import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El capell d’aquest bolet pot fer fins a 10 cm de diàmetre, primer hemisfèric obrint-se fins acabar més o manco pla i ondulat. La cutícula és vellutada, un poc llanosa cap al marge i presenta nombroses esquames més atapeïdes i negroses cap al centre del capell i més clares prop del marge. Les làmines són fràgils, una mica ventrudes, sinuoses i de color blanc a grisenc. El peu s’engruixa a la base i és un poc corbat en el terç inferior, de color blanc amb fibril·les marronenques i un poc rosat cap al terç inferior, però mai blau violaci. Les espores són blanques en massa, ovoidals, de 6-8 x 4,5-5,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      